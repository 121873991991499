import React from "react"
import TextRender from "../TextRender"
import get from "lodash/get"
import { css } from "@emotion/core"
import tw from "twin.macro"
import GatsbyImage from "gatsby-image"
import cn from "classnames"
import Curve from "../Curve"
import ImageProps from "../ImageProps"

export default function Header({ data, split_layout }) {
  switch (get(data, "primary.variation")) {
    case "Stacked":
      return <Stacked data={data} split_layout={split_layout} />
    case "Columns":
      return <Columns data={data} split_layout={split_layout} />
    case "Columns v2":
      return <Columns2 data={data} split_layout={split_layout} />
    case "Label":
      return <Label data={data} split_layout={split_layout} />

    default:
      return null
  }
}

function Label({ data, split_layout }) {
  return (
    <>
      <div
        data-label={data.slice_label}
        className={cn(
          "overflow-hidden relative   bg-light  pb-8 text-center label-id",
          {
            "pb-20 phone:pb-12": split_layout,
          }
        )}
      >
        <div
          tw="max-w-3xl!"
          className={cn("container phone:pt-32 pt-40  pb-16 lg:-mt-6  ")}
        >
          <div>
            <div
              className={cn("prose max-w-none")}
              css={css`
                h1 {
                  ${tw`text-2xl`}
                }
                p {
                  font-size: 16px;
                }
                .primary-button {
                  ${tw`mr-3`}
                }
                img {
                  max-width: 150px;
                  margin: 0 !important;
                }
              `}
            >
              {" "}
              <TextRender text={get(data, "primary.text.raw")} />
            </div>
          </div>
          {data.primary.show_back_button && <div className="px-6"></div>}
        </div>
      </div>
      <Curve className="z-20 text-white" />
    </>
  )
}

function Columns({ data, split_layout }) {
  const image = get(data, "primary.image")
  return (
    <>
      <div
        data-label={data.slice_label}
        className={cn(
          "label-id overflow-hidden relative flex flex-col  justify-center  columns border-b border-light-2",
          {
            "  phone:pb-12": split_layout,
          }
        )}
        css={css`
          @media (min-width: 1024px) {
            min-height: 700px;
          }
        `}
      >
        <div
          className={cn("container h-full phone:mt-32 mt-56 ", {
            " phone:pb-32 pb-40": !split_layout,
          })}
        >
          <div className={"row phone:space-y-12 h-full"}>
            <div
              className={cn("prose max-w-none", {
                "col w-full md:w-1/2 lg:w-7/12 max-w-none ": !split_layout,
                "col w-full   lg:w-8/12 max-w-none ": split_layout,
              })}
              css={css`
                p {
                  font-size: 16px;
                }
                .primary-button {
                  ${tw`mr-3`}
                }
                img {
                  max-width: 150px;
                  margin: 0 !important;
                }
              `}
            >
              {" "}
              <TextRender text={get(data, "primary.text.raw")} />
            </div>
            <div className={"col w-full md:w-1/2 lg:w-5/12"}>
              {image.fluid && (
                <div
                  className="mx-auto"
                  style={{
                    maxWidth: get(data, "primary.image.dimensions.width"),
                  }}
                >
                  <GatsbyImage {...ImageProps(image)} fluid={image.fluid} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function Columns2({ data, split_layout }) {
  const image = get(data, "primary.image")
  return (
    <>
      <div
        data-label={data.slice_label}
        className={cn("label-id overflow-hidden relative  ", {
          "bg-light": split_layout,
          "pb-20 phone:pb-12": split_layout,
        })}
      >
        <div
          className={cn("container phone:pt-32 pt-40 ", {
            " pb-20": !split_layout,
          })}
        >
          <div className={"row  phone:space-y-12"}>
            <div
              className={cn("prose max-w-none", {
                "col w-full md:w-1/2  max-w-none ": !split_layout,
                "col w-full   lg:w-8/12 max-w-none ": split_layout,
              })}
              css={css`
                p {
                  font-size: 16px;
                }
                .primary-button,
                .secondary-button {
                  ${tw`mr-3 text-lg`}
                }

                img {
                  max-width: 150px;
                  margin: 0 !important;
                }
              `}
            >
              {" "}
              <TextRender text={get(data, "primary.text.raw")} />
            </div>
            <div
              className={cn({
                "col w-full md:w-1/2  ": !split_layout,
              })}
            >
              {image.fluid && (
                <div
                  className="mx-auto    overflow-hidden"
                  style={{
                    borderRadius: 5,
                    maxWidth: get(data, "primary.image.dimensions.width"),
                  }}
                >
                  <GatsbyImage {...ImageProps(image)} fluid={image.fluid} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function Stacked({ data, split_layout }) {
  const image = get(data, "primary.image")
  return (
    <>
      <div
        data-label={data.slice_label}
        className={cn("label-id overflow-hidden relative  bg-light", {
          "pb-20 phone:pb-12": split_layout,
        })}
      >
        <div className={cn("container phone:pt-32 pt-40 ")}>
          <div>
            <div
              className={cn("prose  text-center mb-12 mx-auto", {
                "col w-full   lg:w-8/12 max-w-none ": split_layout,
              })}
              css={css`
                p {
                  font-size: 16px;
                }
                .primary-button {
                  ${tw`mr-3`}
                }
                img {
                  max-width: 150px;
                  margin: 0 !important;
                }
              `}
            >
              {" "}
              <TextRender text={get(data, "primary.text.raw")} />
            </div>
            <div className={"mx-auto w-full md:w-11/12 relative top-6"}>
              {image.fluid && (
                <div
                  className="mx-auto"
                  style={{
                    maxWidth: get(data, "primary.image.dimensions.width"),
                  }}
                >
                  <GatsbyImage {...ImageProps(image)} fluid={image.fluid} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
