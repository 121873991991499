import React from "react"
import get from "lodash/get"

import TextRender from "../TextRender"

export default function Clusters({ data }) {
  return (
    <div className="my-20 label-id" data-label={data.slice_label}>
      <div className="container">
        <div className="row   lg:mx-10">
          {data.items &&
            data.items.map((item, index) => {
              return (
                <div key={index} className="col my-8 w-full sm:w-1/2 lg:w-1/3">
                  <div className="prose  mx-auto">
                    <h3 className="font-semibold">{item.label}</h3>
                    <TextRender text={get(item, "text.raw")} type={"cluster"} />
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}
