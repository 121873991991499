import React, { useState } from "react"
import TextRender from "../TextRender"
import get from "lodash/get"
import groupBy from "lodash/groupBy"
import cn from "classnames"
import { css } from "@emotion/core"
import tw from "twin.macro"

import { AnimatePresence, motion } from "framer-motion"
import { linkResolver } from "../../../prismic.config"
import { Link } from "gatsby"
export default function Pricing({ data, pageContext }) {
  const items = data.items
  const tabs = React.useMemo(() => {
    return groupBy(items, "tab")
  }, [])
  const [selectedTab, setSelectedTab] = useState(Object.keys(tabs)[0])
  return (
    <>
      <div
        className="py-16 label-id"
        id="pricing"
        data-label={data.slice_label}
      >
        <div className="container">
          <div className="prose mx-auto text-center">
            <TextRender text={get(data, "primary.text.raw")} />
          </div>
          {tabs && (
            <>
              {" "}
              <div className="flex flex-wrap items-center  justify-center mt-8">
                {Object.keys(tabs).map((key, index) => {
                  return (
                    <div
                      key={index}
                      style={{ minWidth: 214 }}
                      onClick={() => {
                        setSelectedTab(key)
                      }}
                      className={cn(
                        " flex items-center justify-center xs-phone:w-full phone:w-3/4  px-4 py-2 rounded-sm cursor-pointer m-2  ",
                        {
                          "bg-primary text-white filter-shadow":
                            selectedTab === key,
                          "bg-light text-light-text": selectedTab !== key,
                        }
                      )}
                    >
                      {key}
                    </div>
                  )
                })}
              </div>
              <AnimatePresence initial={false} exitBeforeEnter>
                <div
                  className="row justify-center mt-10"
                  css={css`
                    > * {
                      ${tw`-my-4 py-8`}
                    }
                  `}
                >
                  {tabs[selectedTab].map((item, index) => {
                    return (
                      <motion.div
                        initial={{ opacity: 0 }}
                        exit={{
                          opacity: 0,

                          transition: { delay: index * 0.05 },
                        }}
                        animate={{
                          opacity: 1,

                          transition: { delay: index * 0.05 },
                        }}
                        key={item.name + index}
                        className="col w-full sm:w-1/2 lg:w-1/4"
                      >
                        <div className="bg-light hover:bg-white border border-transparent hover:border-primary group filter-shadow rounded p-8 text-center">
                          <div
                            className="my-4 font-semibold text-sm"
                            css={css`
                              ${tw`group-hover:text-primary`}
                            `}
                          >
                            {item.name}
                          </div>
                          <div className="my-6 font-semibold ">
                            <TextRender text={item.price.raw} />
                          </div>
                          <div className="text-light-text text-xs">
                            {item.description}
                          </div>

                          {item.button_link && (
                            <LinkResolver
                              lang={pageContext.lang}
                              style={{ minWidth: 100, fontSize: 13 }}
                              link={item.button_link}
                              className="primary-button mt-8 inline-flex justify-center items-center  px-3 leading-none "
                            >
                              {item.button_label}
                            </LinkResolver>
                          )}
                        </div>
                      </motion.div>
                    )
                  })}
                </div>
              </AnimatePresence>
            </>
          )}
        </div>
      </div>
    </>
  )
}

function LinkResolver({ children, link, lang, ...rest }) {
  let { url, uid, type, ...linkAttrs } = link

  if (uid) {
    return (
      <Link
        to={linkResolver({
          type,
          uid,
          lang,
        })}
        {...rest}
      >
        {children}
      </Link>
    )
  }
  if (url.match(/\/\/#/)) {
    url = url.replace("https://#", "#")

    return (
      <Link to={url} {...rest}>
        {children}
      </Link>
    )
  }
  if (url.match(/\/\/\//)) {
    url = url.replace("https:///", "/")

    return (
      <Link to={url} {...rest}>
        {children}
      </Link>
    )
  }
  return (
    <a href={url} {...rest} {...linkAttrs}>
      {children}
    </a>
  )
}
