import React from "react"
import Curve from "../Curve"
import TextRender from "../TextRender"
import get from "lodash/get"
import { css } from "@emotion/core"
import tw from "twin.macro"
import GatsbyImage from "gatsby-image"
import ImageProps from "../ImageProps"

export default function CaseStudyDetails({ data }) {
  return (
    <>
      <Curve className="bg-white text-light" />
      <div className="py-20 bg-light label-id" data-label={data.slice_label}>
        <div className="container">
          <div className="prose max-w-none">
            <TextRender text={get(data, "primary.about.raw")} />
          </div>
          <div
            className="my-12 -mx-12 row"
            css={css`
              > .col {
                ${tw`px-12`}
              }
            `}
          >
            <div className="w-full col md:w-1/2">
              {" "}
              <div className="prose max-w-none">
                <TextRender text={get(data, "primary.chalange.raw")} />
              </div>
            </div>
            <div className="w-full col md:w-1/2">
              {" "}
              <div className="prose max-w-none">
                <TextRender text={get(data, "primary.solution.raw")} />
              </div>
            </div>
          </div>
          <div
            css={css`
              display: grid !important;
              grid-column-gap: 24px;
              grid-template-columns: 54px 1fr;
            `}
          >
            {get(data, "primary.testimonial_image.fixed") && (
              <GatsbyImage
                css={css`
                  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
                `}
                {...ImageProps(get(data, "primary.testimonial_image"))}
                className="overflow-hidden rounded "
                fixed={get(data, "primary.testimonial_image.fixed")}
              />
            )}
            <div
              className="relative p-4 bg-white rounded-sm"
              css={css`
                filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1));
              `}
            >
              <div
                className="prose max-w-none rotate"
                css={css`
                  p:last-of-type {
                    ${tw`mb-0!`}
                  }
                `}
              >
                <TextRender text={get(data, "primary.testimonial.raw")} />

                <div
                  css={css`
                    position: absolute;
                    ${tw` top-5 -left-1.5 block w-3 h-3 bg-white transform rotate-45  rounded-bl-sm`}
                  `}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Curve className="text-white bg-light" />
      <div
        className="container py-20 lg:py-32 label-id"
        data-label={data.slice_label}
      >
        <div className="row">
          <div className="w-full col md:w-1/2">
            {" "}
            <div className="prose max-w-none">
              <TextRender text={get(data, "primary.outcome.raw")} />
            </div>
          </div>
          <div className="w-full col md:w-1/2 ">
            {get(data, "primary.outcome_image.fluid") && (
              <div className="mx-auto">
                <GatsbyImage
                  {...ImageProps(get(data, "primary.outcome_image"))}
                  fluid={get(data, "primary.outcome_image.fluid")}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
