import React from "react"
import TextRender from "../TextRender"
import get from "lodash/get"
import { Link } from "gatsby"
import ImageResolver from "../ImageResolver"
import { linkResolver } from "../../../prismic.config"

export default function CaseStudies({ data, pageContext }) {
  return (
    <div className="my-20 label-id" data-label={data.slice_label}>
      <div className="container">
        <div className="mx-auto prose text-center">
          <TextRender text={get(data, "primary.text.raw")} />
        </div>
        {data.items && (
          <div className="my-16 row">
            {data.items.map((item, index) => {
              const image = get(
                item,
                "case_study.document.data.body[0].primary.image.fluid"
              )
              const title = get(item, "case_study.document.data.title.text")
              const link = get(item, "case_study.document.uid")
              const client_name = get(
                item,
                "case_study.document.data.body[1].primary.client_name"
              )
              return (
                <Link
                  to={linkResolver({
                    type: "page",
                    uid: link,
                    lang: pageContext.lang,
                  })}
                  className="w-full my-4 col md:w-1/2 lg:w-1/3"
                  key={index}
                >
                  {image && (
                    <ImageResolver
                      image={get(
                        item,
                        "case_study.document.data.body[0].primary.image"
                      )}
                      className="rounded"
                    />
                  )}
                  <div className="mt-3 text-sm ">
                    <h4 className="font-semibold">{title}</h4>
                    <p className="text-light-text">{client_name}</p>
                  </div>
                </Link>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
