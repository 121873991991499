import React from "react"
import TextRender from "../TextRender"
import get from "lodash/get"
import { css } from "@emotion/core"
import tw from "twin.macro"
import ImageResolver from "../ImageResolver"

export default function Forkpage({ data }) {
  const items = get(data, "primary.cta_section.document.data.options")
  return (
    <>
      <div
        className="-mt-20 pb-24 relative z-40 label-id"
        id="contact"
        data-label={data.slice_label}
      >
        <div className="container">
          <div className="mx-auto prose text-center">
            <TextRender text={get(data, "primary.text.raw")} />
          </div>
          <div className="grid gap-4 grid-cols-2 lg:grid-cols-4 mt-9">
            {" "}
            {items &&
              items.map((item, index) => {
                return (
                  <div
                    key={index}
                    className=" flex flex-col items-stretch   w-full hover:bg-light border border-transparent hover:border-primary  bg-white group filter-shadow rounded p-4   text-center   max-w-none "
                    css={css`
                      .primary-button {
                        ${tw`w-full`}
                      }
                    `}
                  >
                    <div
                      className="relative mt-8  mb-8 group"
                      css={css`
                        * {
                          transition: all 300ms;
                        }
                      `}
                    >
                      <div
                        className="absolute z-0  transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2  -rotate-140"
                        css={css`
                          width: 60px;
                          height: 58px;
                        `}
                      >
                        {" "}
                        <div
                          className="  "
                          css={css`
                            width: 60px;
                            height: 58px;
                            transform: rotate(0deg);
                            .group:hover & {
                              animation: spinning 7s linear infinite;
                              background: rgba(25, 188, 21, 0.336);
                            }
                            @keyframes spinning {
                              from {
                                transform: rotate(0deg);
                              }
                              to {
                                transform: rotate(360deg);
                              }
                            }
                            background: linear-gradient(
                              180deg,
                              rgba(255, 255, 255, 0.7) 0%,
                              rgba(206, 241, 205, 0.7) 58.68%,
                              rgba(25, 188, 21, 0.336) 100%
                            );
                          `}
                        ></div>
                      </div>
                      <div
                        className="relative z-10 mx-auto"
                        style={{ maxWidth: 50 }}
                      >
                        <ImageResolver image={item.image} />
                      </div>
                    </div>
                    <div
                      className="prose"
                      css={css`
                        h3,
                        strong {
                          ${tw`text-base!`}
                        }
                        p {
                          ${tw`text-sm!`}
                        }
                      `}
                    >
                      <TextRender text={item.content.raw} />
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}
