import React from "react"
import get from "lodash/get"

import Curve from "../Curve"
import TextRender from "../TextRender"
import { Link } from "gatsby"
import { linkResolver } from "../../../prismic.config"

export default function DeepDiveIntro({ data, pageContext }) {
  return (
    <>
      <Curve className="text-light" />
      <div className="bg-light">
        {" "}
        <div className="container py-8">
          <div className="prose max-w-none">
            <TextRender text={get(data, "primary.intro")} />
          </div>
          <div className="my-16 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 md:gap-8  lg:gap-10">
            {data.items.map((item, index) => {
              const article = get(item, "deep_dives.document")
              return (
                <div key={index}>
                  <Link
                    to={linkResolver({
                      type: article.type,
                      lang: pageContext.lang,
                      uid: article.uid,
                    })}
                    className="block items-stretch   w-full border border-transparent hover:border-primary     bg-white   group filter-shadow rounded px-4 py-3 text-sm"
                  >
                    <h4 className="font-semibold mb-1">
                      {get(article, "data.title.text")}
                    </h4>
                    <p className="text-light-text">
                      {" "}
                      {get(article, "data.text.excerpt")}
                    </p>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <Curve className="text-white" />
    </>
  )
}
