import React from "react"
import get from "lodash/get"
import { Elements } from "prismic-reactjs"
import { FaCheck } from "react-icons/fa"
import { css } from "@emotion/core"

import TextRender from "../TextRender"
import ImageResolver from "../ImageResolver"

export default function Services({ data }) {
  const items = data.items
  return (
    <>
      <div className="my-32 label-id services" data-label={data.slice_label}>
        <div className="container lg:max-w-screen-lg">
          <div className="mx-auto prose text-center">
            <TextRender text={get(data, "primary.text.raw")} />
          </div>
          {items && (
            <div className=" mt-10 row justify-evenly">
              {items.map((item, index) => {
                return (
                  <div
                    className="w-full my-10 text-center col md:w-1/2 lg:w-1/3 md:px-4"
                    css={css`
                      max-width: 260px;
                    `}
                    key={index}
                  >
                    <div
                      className="relative mb-8 group"
                      css={css`
                        * {
                          transition: all 300ms;
                        }
                      `}
                    >
                      <div
                        className="absolute z-0  transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2  -rotate-140"
                        css={css`
                          width: 60px;
                          height: 58px;
                        `}
                      >
                        {" "}
                        <div
                          className="  "
                          css={css`
                            width: 60px;
                            height: 58px;
                            transform: rotate(0deg);
                            .group:hover & {
                              animation: spinning 7s linear infinite;
                              background: rgba(25, 188, 21, 0.336);
                            }
                            @keyframes spinning {
                              from {
                                transform: rotate(0deg);
                              }
                              to {
                                transform: rotate(360deg);
                              }
                            }
                            background: linear-gradient(
                              180deg,
                              rgba(255, 255, 255, 0.7) 0%,
                              rgba(206, 241, 205, 0.7) 58.68%,
                              rgba(25, 188, 21, 0.336) 100%
                            );
                          `}
                        ></div>
                      </div>
                      <div
                        className="relative z-10 mx-auto"
                        style={{ maxWidth: 50 }}
                      >
                        <ImageResolver image={item.image} />
                      </div>
                    </div>
                    <div
                      className="prose max-w-none"
                      css={css`
                        p {
                          font-size: 14px;
                        }
                      `}
                    >
                      <TextRender
                        text={get(item, "text.raw")}
                        serializer={serializer}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

function serializer(type, element, content, children, key) {
  switch (type) {
    case Elements.list:
      return <ul className="mb-6">{children}</ul>
    case Elements.listItem:
      return (
        <li
          tw="relative"
          css={css`
            &:before {
              display: none !important;
            }
          `}
        >
          <FaCheck tw="absolute top-1.5 left-0 mt-0! text-primary" /> {children}
        </li>
      )
      return null
    default:
      return null
  }
}
