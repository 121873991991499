import React from "react"
import TextRender from "../TextRender"
import get from "lodash/get"
import cn from "classnames"
import { css } from "@emotion/core"
import tw from "twin.macro"
import ImageResolver from "../ImageResolver"

export default function AboutUs({ data }) {
  return (
    <>
      <div className="mt-16 mb-32 label-id" data-label={data.slice_label}>
        <div className="container">
          <div
            className="-mx-8 row"
            css={css`
              .col {
                ${tw`px-8`}
              }
            `}
          >
            <div className="w-full col md:w-1/2 lg:w-7/12">
              {" "}
              <div className="prose max-w-none">
                <TextRender text={get(data, "primary.text.raw")} />
              </div>
            </div>
            <div
              className={cn(
                "col w-full md:w-1/2 lg:w-5/12 phone:mb-8 phone:order-first ",
                {
                  "order-first": get(data, "primary.flipped"),
                }
              )}
            >
              {get(data, "primary.image.fluid") && (
                <div className="overflow-hidden rounded-sm filter-shadow">
                  <ImageResolver image={data.primary.image} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
