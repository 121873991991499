import React, { useEffect } from "react"
import TextRender from "../TextRender"
import get from "lodash/get"

export default function Jobs({ data }) {
  useEffect(() => {
    if (typeof window !== "undefined") {
      let script = document.createElement("script")
      script.src = get(data, "primary.script_src")
      document.getElementById("join-widget").appendChild(script)
    }
  }, [])
  return (
    <>
      <div className="  py-16 label-id" id="jobs" data-label={data.slice_label}>
        <div className="container">
          <div className="prose text-center mx-auto">
            <TextRender text={get(data, "primary.text.raw")} />
          </div>

          <div id="join-widget"></div>
        </div>
      </div>
    </>
  )
}
