import React, { useState } from "react"
import TextRender from "../TextRender"
import get from "lodash/get"
import { useForm } from "react-hook-form"

import { css } from "@emotion/core"
import tw from "twin.macro"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}
export default function ContactForm({ data }) {
  const form = get(data, "primary.form.document.data", {})
  const [submitted, setSubmited] = useState(false)
  const schema = yup.object().shape({
    name: yup.string().required(form.required_field_error),
    company: yup.string().required(form.required_field_error),
    email: yup
      .string()
      .required(form.required_field_error)
      .email(form.invalid_email_error),
    phone: yup.string().required(form.required_field_error),
    message: yup.string().required(form.required_field_error),
    tos: yup.bool().oneOf([true], form.privacy_policy_error),
  })
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  })
  function onSubmit(values) {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...values }),
    }).then((res) => {
      if (res.status == "200") {
        setSubmited(true)
      }
    })
  }

  return (
    <>
      <div
        className="pt-16 pb-24 bg-light label-id"
        id="contact"
        data-label={data.slice_label}
      >
        <div className="container">
          <div className="mx-auto prose text-center">
            <TextRender text={get(data, "primary.text.raw")} />
          </div>
          <div
            className="max-w-3xl mx-auto mt-10"
            css={css`
              .grid > * {
                ${tw`flex flex-col items-stretch`}
              }
              label span {
                ${tw`font-semibold  text-sm`}
                .ast {
                  ${tw`text-red-500`}
                }
              }
              .error {
                ${tw`text-xs text-red-500`}
              }
              input,
              textarea {
                ${tw`border rounded focus:ring-primary focus:border-primary border-light-text p-1`}
              }
            `}
          >
            {!submitted && (
              <form
                className="space-y-4"
                onSubmit={handleSubmit(onSubmit)}
                data-netlify="true"
                name="contact"
              >
                <p className="hidden">
                  <input type="hidden" name="form-name" value="contact" />
                </p>
                <div className="grid gap-x-3.5 gap-y-2 text-left md:grid-cols-2">
                  <label>
                    <span>
                      {" "}
                      {form.name_label} <i className="ast">*</i>
                    </span>
                    <input name="name" type="text" ref={register} />
                    {errors["name"] && (
                      <span className="error">{errors["name"].message}</span>
                    )}
                  </label>
                  <label>
                    <span>
                      {" "}
                      {form.company_label} <i className="ast">*</i>
                    </span>
                    <input name="company" type="text" ref={register} />
                    {errors["company"] && (
                      <span className="error">{errors["company"].message}</span>
                    )}
                  </label>
                  <label>
                    <span>
                      {" "}
                      {form.email_label} <i className="ast">*</i>
                    </span>
                    <input name="email" type="text" ref={register} />
                    {errors["email"] && (
                      <span className="error">{errors["email"].message}</span>
                    )}
                  </label>
                  <label>
                    <span>
                      {" "}
                      {form.phone_label} <i className="ast">*</i>{" "}
                    </span>
                    <input name="phone" type="text" ref={register} />
                    {errors["phone"] && (
                      <span className="error">{errors["phone"].message}</span>
                    )}
                  </label>
                  <label className="md:col-span-2 pt-2">
                    <span>
                      {" "}
                      {form.message_label} <i className="ast">*</i>
                    </span>
                    <textarea
                      name="message"
                      style={{ minHeight: 150 }}
                      type="text"
                      ref={register}
                    />
                    {errors["message"] && (
                      <span className="error">{errors["message"].message}</span>
                    )}
                  </label>
                </div>
                <div tw="text-sm">
                  <div
                    className="mb-1"
                    css={css`
                      a {
                        ${tw`font-semibold text-primary hover:underline`}
                      }
                    `}
                  >
                    <label className="block">
                      <input
                        type="checkbox"
                        name="tos"
                        ref={register}
                        className="rounded-sm text-primary focus:ring-primary w-3 h-3 relative -top-0.5 mr-1"
                      />{" "}
                      <TextRender
                        text={get(form, "privacy_policy_text.raw")}
                        serializer={(type, element, content, children, key) => {
                          switch (type) {
                            case "paragraph":
                              return <>{children}</>

                            default:
                              break
                          }
                        }}
                      />
                    </label>
                    {errors["tos"] && (
                      <span className="error">{errors["tos"].message}</span>
                    )}
                  </div>

                  <div>
                    <label className="block">
                      {" "}
                      <input
                        name="newsletter"
                        ref={register}
                        type="checkbox"
                        className="rounded-sm text-primary focus:ring-primary w-3 h-3 relative -top-0.5 mr-1"
                      />{" "}
                      <TextRender
                        text={get(form, "newsletter_text.raw")}
                        serializer={(type, element, content, children, key) => {
                          switch (type) {
                            case "paragraph":
                              return <>{children}</>

                            default:
                              break
                          }
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="primary-button sm-phone:w-full"
                  >
                    {form.send_button_label}
                  </button>
                </div>
              </form>
            )}
            {submitted && (
              <div className="text-center">
                <TextRender text={get(form, "success_message.raw")} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
