import React from "react"
import TextRender from "../TextRender"
import get from "lodash/get"
import { css } from "@emotion/core"
import tw from "twin.macro"
import cn from "classnames"
import ImageResolver from "../ImageResolver"

export default function HeaderV32({ data }) {
  const inverse = data.primary.inverse
  return (
    <div
      css={
        inverse
          ? css`
              ${tw`bg-light`}
              &+ .trust {
                ${tw`bg-light`}
              }
            `
          : css`
              .c {
                ${tw`bg-light hover:bg-white`}
              }
            `
      }
    >
      <div
        className={cn("overflow-hidden relative  label-id ")}
        data-label={data.slice_label}
      >
        <div className={cn("container phone:pt-32 pt-40 ")}>
          <div className="row items-center">
            <div className="col w-full md:w-1/2  ">
              <div>
                <div
                  className={cn("prose max-w-none    mb-12 mx-auto")}
                  css={[
                    css`
                      h1 {
                        ${tw`text-4xl`}
                      }
                      p {
                        font-size: 18px;
                        color: #000;
                      }
                      strong {
                        ${tw` font-bold`}
                      }

                      img {
                        max-width: 150px;
                        margin: 0 !important;
                      }
                      .primary-button,
                      .secondary-button {
                        ${tw`  phone:w-full text-lg py-2 mb-4 md:mr-3 md:px-6`}
                      }
                    `,
                  ]}
                >
                  {" "}
                  <TextRender text={get(data, "primary.text.raw")} />
                </div>
              </div>
            </div>
            <div className="col phone:hidden md:w-2/5 md:ml-auto ">
              <ImageResolver
                image={get(data, "primary.featured_image")}
                loading="eager"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>

        <div
          className={cn("container prose    mt-12 lg:mt-20  mx-auto")}
          css={css`
            p {
              ${tw`text-lg text-black`}
            }
          `}
        >
          {" "}
          <TextRender text={get(data, "primary.logos.raw")} />
        </div>
      </div>
      <div className="container phone:mt-4 pb-6 ">
        {data.items && (
          <div className="flex flex-wrap items-center   phone:justify-center">
            {data.items.map(({ logo }) => {
              if (!logo.fluid) {
                return null
              }
              const maxWidth =
                logo.dimensions.width > 75 ? 75 : logo.dimensions.width
              return (
                <div
                  className="w-full mr-4 md:mr-8  my-3 md:my-4"
                  css={css`
                    max-width: ${maxWidth / 1.5}px;
                    @media (min-width: 1024px) {
                      max-width: ${maxWidth}px;
                    }
                  `}
                >
                  <ImageResolver image={logo} />
                </div>
              )
            })}
          </div>
        )}
      </div>
      <hr />
    </div>
  )
}
