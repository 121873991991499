import React from "react"
import TextRender from "../TextRender"
import get from "lodash/get"
import { css } from "@emotion/core"
import tw from "twin.macro"
import GatsbyImage from "gatsby-image"
import cn from "classnames"
import ImageProps from "../ImageProps"
import CtaCards from "./CtaCards"

export default function HeaderV2({ data }) {
  const inverse = data.primary.inverse
  return (
    <div
      css={
        inverse
          ? css`
              ${tw`bg-light`}
              &+ .trust {
                ${tw`bg-light`}
              }
            `
          : css`
              .c {
                ${tw`bg-light hover:bg-white`}
              }
            `
      }
    >
      <div
        className={cn("overflow-hidden relative  label-id ")}
        data-label={data.slice_label}
      >
        <div className={cn("container phone:pt-32 pt-40 ")}>
          <div>
            <div
              className={cn("prose  text-center mb-12 mx-auto")}
              css={[
                css`
                  h1 {
                    ${tw`text-4xl`}
                  }
                  p {
                    font-size: 18px;
                    color: #000;
                  }
                  strong {
                    ${tw` font-bold`}
                  }

                  img {
                    max-width: 150px;
                    margin: 0 !important;
                  }
                `,
                data.primary.collapse_on_mobile &&
                  css`
                    .primary-button {
                      ${tw`md:hidden w-full text-xl py-3 max-w-md`}
                    }
                  `,
              ]}
            >
              {" "}
              <TextRender text={get(data, "primary.text.raw")} />
            </div>
          </div>
        </div>

        <div
          className={cn("lg:max-w-7xl container my-8 md:my-12", {
            "phone:hidden": get(data, "primary.collapse_on_mobile"),
          })}
          css={css``}
        >
          <CtaCards items={get(data, "primary.cta.document.data.options")} />
        </div>
        <div
          className={cn("container prose  text-center mt-12 lg:mt-20  mx-auto")}
          css={css`
            p {
              ${tw`text-lg text-black`}
            }
          `}
        >
          {" "}
          <TextRender text={get(data, "primary.logos.raw")} />
        </div>
      </div>
      <div className="container phone:mt-4 pb-6 ">
        {data.items && (
          <div className="flex flex-wrap items-center justify-center phone:justify-center">
            {data.items.map(({ logo }) => {
              if (!logo.fluid) {
                return null
              }
              const maxWidth =
                logo.dimensions.width > 75 ? 75 : logo.dimensions.width
              return (
                <div
                  className="w-full mx-2 md:mx-4  my-3 md:my-4"
                  css={css`
                    max-width: ${maxWidth / 1.5}px;
                    @media (min-width: 1024px) {
                      max-width: ${maxWidth}px;
                    }
                  `}
                >
                  <GatsbyImage fluid={logo.fluid} {...ImageProps(logo)} />
                </div>
              )
            })}
          </div>
        )}
      </div>
      <hr />
    </div>
  )
}
