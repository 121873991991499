import React from "react"
import TextRender from "../TextRender"
import get from "lodash/get"
import Curve from "../Curve"
import cn from "classnames"
import { Elements } from "prismic-reactjs"
import { FaCheck } from "react-icons/fa"
import tw from "twin.macro"
import { css } from "@emotion/core"
import ImageResolver from "../ImageResolver"

export default function Pitch({ data }) {
  const items = data.items
  return (
    <>
      <Curve className="text-light" />
      <div className="bg-light md:p-12 label-id " data-label={data.slice_label}>
        <div className="container py-12 ">
          <div className="mx-auto prose text-center max-w-none">
            <TextRender text={get(data, "primary.text.raw")} />
          </div>
          {items &&
            items.map((item, index) => {
              return (
                <div className="py-2 my-24 row " key={index}>
                  <div className="w-full col md:w-1/2 lg:w-7/12">
                    <div className="prose max-w-none">
                      <TextRender
                        text={get(item, "text.raw")}
                        serializer={serializer}
                      />
                    </div>
                  </div>
                  <div
                    className={cn(
                      "col w-full md:w-1/2 lg:w-5/12 phone:order-first phone:mb-8",
                      {
                        "md:order-first": index % 2 === 0,
                      }
                    )}
                  >
                    {item.image.fluid && (
                      <div
                        className="mx-auto"
                        style={{
                          maxWidth: get(item, "image.dimensions.width"),
                        }}
                      >
                        <ImageResolver image={item.image} />
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
        </div>
      </div>
      <Curve className="text-white" />
    </>
  )
}

function serializer(type, element, content, children, key) {
  switch (type) {
    case Elements.list:
      return <ul className="mb-6">{children}</ul>
    case Elements.listItem:
      return (
        <li
          tw="relative "
          css={css`
            .prose ul > & {
              ${tw`leading-6! my-0.5`}
            }
            &:before {
              display: none !important;
            }
          `}
        >
          <FaCheck tw="absolute top-1.5 left-0 mt-0! text-primary" /> {children}
        </li>
      )
    default:
      return null
  }
}
