import React from "react"
import get from "lodash/get"
import { css } from "@emotion/core"
import TextRender from "../TextRender"
import tw from "twin.macro"
import cn from "classnames"
import ImageResolver from "../ImageResolver"

export default function SplitContent({ data }) {
  const image = get(data, "primary.image")
  return (
    <div
      className={cn("phone:pb-8 py-16 label-id ", {
        "bg-light": get(data, "primary.background_highlight"),
      })}
      data-label={data.slice_label}
      css={css`
        &:not(:last-of-type) {
          padding-bottom: 0;
        }
      `}
    >
      <div className="container">
        <div className="row">
          <div className="col w-full md:w-3/5">
            {" "}
            <div
              className="prose max-w-none"
              css={css`
                p {
                  ${tw`text-lg text-black `}
                }
                .primary-button,
                .secondary-button {
                  ${tw`text-lg text-black px-4 mr-2`}
                }
                li {
                  ${tw`text-black text-lg`}
                }
                li:before {
                  display: none;
                }
                h3 {
                  ${tw`text-2xl mb-8 `}
                }
              `}
            >
              <TextRender text={get(data, "primary.text.raw")} />
            </div>
          </div>
          <div className="col w-full md:w-2/5 phone:order-first phone:mb-6 ">
            {image && (
              <div className="rounded  overflow-hidden">
                <ImageResolver image={image} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
