import React from "react"
import { Link } from "gatsby"
import Logo from "@/icons/logo"

import FormSelector from "@/components/FormSelector"
import { linkResolver } from "../../../prismic.config"

import TextRender from "@/components/TextRender"

export default function QuoteFormWrapper({ data, lang }) {
  const form = data.primary.form?.document?.data
  return (
    <div className="container pb-20">
      <div className="mx-auto bg-white border border-gray-300 shadow-sm rounded-md max-w-md relative z-30 px-5 py-4 -mt-10">
        <div className="flex justify-center py-3">
          <Link
            to={linkResolver({
              type: "page",
              uid: "homepage",
              lang,
            })}
          >
            <Logo className="mx-auto" />
          </Link>
        </div>
        <div className="my-4 text-center">
          <div className="mb-2 text-2xl font-semibold">
            <TextRender text={form?.title?.raw} />
          </div>
          <TextRender text={form?.subtitle?.raw} />
        </div>
        {form && <FormSelector type={form.type} lang={lang} data={form} />}
      </div>
      {form?.additional_text?.raw && (
        <div className="mx-auto mt-5 prose text-center text-black">
          <TextRender text={form?.additional_text?.raw} />
        </div>
      )}
    </div>
  )
}
