import React from "react"
import { Link } from "gatsby"
import get from "lodash/get"
import { css } from "@emotion/core"
import tw from "twin.macro"

import TextRender from "../TextRender"
import ImageResolver from "../ImageResolver"
import { linkResolver } from "../../../prismic.config"

export default function SidebarCTA({ data }) {
  const items = data && data.options
  return (
    <>
      <h3
        className="text-lg -mt-3 text-black  tablet:hidden label-id"
        data-label={"sidebar"}
      >
        {get(data, "sidebar_title.text")}
      </h3>
      <hr className="border-t-2 border-light mt-2 mb-4  tablet:hidden" />
      <div className="grid gap-4">
        {" "}
        {items &&
          items.map((item, index) => {
            const Tag =
              get(item, "sidebar_link.link_type") == "Document" ? Link : "a"
            const attrs =
              get(item, "sidebar_link.link_type") == "Document"
                ? { to: linkResolver(get(item, "sidebar_link.document")) }
                : {
                    href: get(item, "sidebar_link.url"),
                    target: get(item, "sidebar_link.target"),
                  }
            return (
              <Tag
                {...attrs}
                key={index}
                className=" grid gap-4   items-stretch   w-full border border-transparent hover:border-primary    hover:bg-white bg-light group filter-shadow rounded p-4        "
                css={css`
                  grid-template-columns: 62px 1fr;
                  .primary-button {
                    ${tw`w-full`}
                  }
                `}
              >
                <div
                  className="relative mt-2 group w-12 mx-auto flex items-center justify-center"
                  css={css`
                    * {
                      transition: all 300ms;
                    }
                  `}
                >
                  <div
                    className="absolute z-0  transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2  -rotate-140"
                    css={css`
                      width: calc(60px * 0.9);
                      height: calc(60px * 0.9);
                    `}
                  >
                    {" "}
                    <div
                      className="  "
                      css={css`
                        width: calc(60px * 0.9);
                        height: calc(60px * 0.9);
                        transform: rotate(0deg);
                        /* .group:hover & {
                          animation: spinning 7s linear infinite;
                          background: rgba(25, 188, 21, 0.336);
                        } */
                        @keyframes spinning {
                          from {
                            transform: rotate(0deg);
                          }
                          to {
                            transform: rotate(360deg);
                          }
                        }
                        background: linear-gradient(
                          180deg,
                          rgba(255, 255, 255, 0.7) 0%,
                          rgba(206, 241, 205, 0.7) 58.68%,
                          rgba(25, 188, 21, 0.336) 100%
                        );
                      `}
                    ></div>
                  </div>
                  <div className="relative z-10 w-10 ">
                    <ImageResolver
                      image={item.image}
                      dimensions={{ width: 40, height: 40 }}
                    />
                  </div>
                </div>
                <div
                  className="prose"
                  css={css`
                    h3,
                    strong {
                      ${tw`text-sm! mb-0.5`}
                    }
                    p {
                      ${tw`text-sm!`}
                    }
                  `}
                >
                  <TextRender text={item.content.raw} />
                </div>
              </Tag>
            )
          })}
      </div>
    </>
  )
}
