import React from "react"
import TextRender from "../TextRender"
import get from "lodash/get"
import ImageResolver from "../ImageResolver"

export default function Team({ data }) {
  return (
    <div className="py-16 bg-light label-id" data-label={data.slice_label}>
      <div className="container">
        <div className="mx-auto prose text-center">
          <TextRender text={get(data, "primary.text.raw")} />
        </div>
        <div className="grid grid-cols-2 gap-6 mt-8 md:grid-cols-3 lg:grid-cols-4">
          {data.items &&
            data.items.map((item, index) => {
              const image = item.image
              return (
                <div key={index} className="text-center">
                  {image && (
                    <div className="w-24 mx-auto mb-2 md:w-36">
                      <ImageResolver className="rounded-full" image={image} />
                    </div>
                  )}
                  <div className="font-semibold">{item.name}</div>
                  <div className="text-sm font-semibold text-light-text">
                    {item.title1}
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}
