import { graphql } from "gatsby"
import React from "react"
import get from "lodash/get"
import { css } from "@emotion/core"
import useMeasure from "react-use-measure"

import Layout from "../components/Layout"
import Header from "../components/slices/Header"
import Logos from "../components/slices/Logos"
import Pitch from "../components/slices/Pitch"
import Services from "../components/slices/Services"
import Testimonials from "../components/slices/Testimonials"
import Pricing from "../components/slices/Pricing"
import AboutUs from "../components/slices/AboutUs"
import ContactForm from "../components/slices/ContactForm"
import CTASection from "../components/slices/CTASection"
import Team from "../components/slices/Team"
import Jobs from "../components/slices/Jobs"
import JobsFreelance from "../components/slices/JobsFreelance"
import Text from "../components/slices/Text"
import Accordion from "../components/slices/Accordion"
import SidebarCTA from "../components/slices/SidebarCTA"
import Curve from "../components/Curve"
import Clients from "../components/slices/Clients"
import CaseStudyDetails from "../components/slices/CaseStudyDetails"
import CaseStudies from "../components/slices/CaseStudies"
import Clusters from "../components/slices/Clusters"
import Forkpage from "../components/slices/Forkpage"
import RegistrationCtaSection from "../components/slices/RegistrationCtaSection"
import Forms from "../components/slices/Forms"
import Trust from "../components/slices/Trust"
import HeaderV2 from "../components/slices/HeaderV2"
import HeaderV32 from "../components/slices/HeaderV32"
import DownloadHero from "../components/slices/DownloadHero"
import CtaText from "../components/slices/CtaText"
import SplitContent from "../components/slices/SplitContent"
import Related from "../components/slices/Related"
import DeepDiveIntro from "../components/slices/DeepDiveIntro"
import SuccessPageMessage from "../components/slices/SuccessPageMessage"

export default function Page({ data, pageContext }) {
  const slices = get(data, "prismicPage.data.body")
  const split_layout = get(data, "prismicPage.data.split_layout_with_form")
  const distraction_free_mode = get(
    data,
    "prismicPage.data.distraction_free_mode"
  )
  const [ref, { height }] = useMeasure()

  return (
    <Layout
      distraction_free_mode={distraction_free_mode}
      pageContext={pageContext}
      seo={get(data, "prismicPage.seo")}
    >
      {!split_layout ? (
        <div
          className={distraction_free_mode ? "bg-light pt-40 min-h-screen" : ""}
        >
          {" "}
          <SliceMachineHeader slices={slices} pageContext={pageContext} />
          <SliceMachine slices={slices} pageContext={pageContext} />
        </div>
      ) : (
        <>
          <div ref={ref}>
            <SliceMachineHeader
              split_layout
              slices={slices}
              pageContext={pageContext}
            />
          </div>
          <Curve className="text-white" />
          <div className="container pb-20">
            <div className="row lg:flex-nowrap">
              <div
                className="w-full col lg:auto"
                css={css`
                  .prose > p:last-of-type .primary-button {
                    @media (max-width: 1023px) {
                      display: none !important;
                    }
                  }
                `}
              >
                <SliceMachine slices={slices} pageContext={pageContext} />
              </div>
              <div
                className="flex-shrink-0 w-full col lg:w-4/12"
                css={css`
                  @media (min-width: 1024px) {
                    margin-top: calc(${-height}px + 170px);
                  }
                `}
              >
                <div className="bg-white lg:filter-shadow lg:p-6 lg:sticky top-24">
                  <SidebarCTA
                    data={get(
                      data,
                      "prismicPage.data.contact_form.document.data"
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  )
}

function SliceMachineHeader({ slices, split_layout, pageContext }) {
  return (
    slices &&
    slices.map((slice, index) => {
      switch (slice.__typename) {
        case "PrismicPageBodyHeader":
          return <Header key={index} split_layout={split_layout} data={slice} />
        case "PrismicPageBodyHeaderV2":
          return (
            <HeaderV2 key={index} split_layout={split_layout} data={slice} />
          )
        case "PrismicPageBodyHeader32":
          return (
            <HeaderV32 key={index} split_layout={split_layout} data={slice} />
          )
        case "PrismicPageBodyDownload":
          return (
            <DownloadHero
              key={index}
              split_layout={split_layout}
              pageContext={pageContext}
              data={slice}
            />
          )

        default:
          return null
      }
    })
  )
}
function SliceMachine({ slices, pageContext }) {
  return (
    slices &&
    slices.map((slice, index) => {
      switch (slice.__typename) {
        case "PrismicPageBodyHeader":
        case "PrismicPageBodyHeaderV2":
        case "PrismicPageBodyHeader32":
        case "PrismicPageBodyDownload":
          return null
        case "PrismicPageBodyLogos":
          return <Logos key={index} data={slice} />
        case "PrismicPageBodyPitch":
          return <Pitch key={index} data={slice} />
        case "PrismicPageBodyServices":
          return <Services key={index} data={slice} />
        case "PrismicPageBodyTestimonials":
          return <Testimonials key={index} data={slice} />
        case "PrismicPageBodyPricing":
          return <Pricing key={index} data={slice} pageContext={pageContext} />
        case "PrismicPageBodyCtaSection":
          return <CTASection key={index} data={slice} />
        case "PrismicPageBodyAboutUs":
          return <AboutUs key={index} data={slice} />
        case "PrismicPageBodyContactForm":
          return <ContactForm key={index} data={slice} />
        case "PrismicPageBodyTeam":
          return <Team key={index} data={slice} />
        case "PrismicPageBodyJobs":
          return <Jobs key={index} data={slice} />
        case "PrismicPageBodyJobsFreelance":
          return <JobsFreelance key={index} data={slice} />
        case "PrismicPageBodyText":
          return <Text key={index} data={slice} />
        case "PrismicPageBodyAccordion":
          return <Accordion key={index} data={slice} />
        case "PrismicPageBodyClients":
          return <Clients key={index} data={slice} />
        case "PrismicPageBodyCaseStudyDetails":
          return <CaseStudyDetails key={index} data={slice} />
        case "PrismicPageBodyCaseStudies":
          return (
            <CaseStudies key={index} data={slice} pageContext={pageContext} />
          )
        case "PrismicPageBodyClusters":
          return <Clusters key={index} data={slice} />
        case "PrismicPageBodyForkpage":
          return <Forkpage key={index} data={slice} />
        case "PrismicPageBodyRegistrationCtaSection":
          return <RegistrationCtaSection key={index} data={slice} />
        case "PrismicPageBodyRegistrationForm":
          return (
            <Forms
              key={index}
              data={slice}
              lang={pageContext?.lang}
            />
          )
        case "PrismicPageBodyTrust":
          return <Trust key={index} data={slice} lang={pageContext.lang} />
        case "PrismicPageBodyCtaText":
          return <CtaText key={index} data={slice} lang={pageContext.lang} />
        case "PrismicPageBodySplitContent":
          return (
            <SplitContent key={index} data={slice} lang={pageContext.lang} />
          )
        case "PrismicPageBodyRelated":
          return <Related key={index} data={slice} pageContext={pageContext} />
        case "PrismicPageBodyDiveIntro":
          return (
            <DeepDiveIntro key={index} data={slice} pageContext={pageContext} />
          )
        case "PrismicPageBodySuccessPageMessage":
          return (
            <SuccessPageMessage
              key={index}
              data={slice}
              pageContext={pageContext}
            />
          )

        default:
          return (
            <div className="overflow-x-auto" key={index}>
              <pre>{JSON.stringify(slice, null, 2)}</pre>
            </div>
          )
      }
    })
  )
}

export const query = graphql`
  query($uid: String!, $lang: String) {
    prismicPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      seo: data {
        meta_title
        meta_description
        meta_keywords
        meta_image {
          url
        }
      }
      data {
        distraction_free_mode
        split_layout_with_form
        contact_form {
          document {
            ... on PrismicCallToAction {
              id
              data {
                sidebar_title {
                  text
                }
                options {
                  sidebar_link {
                    link_type
                    document {
                      ... on PrismicPage {
                        id
                        uid
                        type
                        lang
                        url
                      }
                    }
                    target
                    url
                  }

                  image {
                    url
                    fluid(imgixParams: { maxW: 1200 }) {
                      ...GatsbyPrismicImageFluid_noBase64
                    }
                    alt
                    dimensions {
                      height
                      width
                    }
                  }
                  content {
                    raw
                  }
                }
              }
            }
          }
        }
        body {
          __typename
          ... on PrismicPageBodyHeader {
            slice_label
            id
            primary {
              variation
              text {
                raw
              }
              show_back_button
              image {
                dimensions {
                  width
                  height
                }
                alt
                url
                fluid(imgixParams: { maxW: 1600, q: 100 }) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicPageBodyLogos {
            slice_label
            id
            items {
              logo {
                dimensions {
                  width
                  height
                }
                url
                alt
                fluid(imgixParams: { q: 100, w: 1 }) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicPageBodyPitch {
            slice_label
            id
            primary {
              text {
                raw
              }
            }
            items {
              text {
                raw
              }
              image {
                alt
                url
                dimensions {
                  width
                  height
                }
                fluid(imgixParams: { maxW: 500 }) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicPageBodyServices {
            slice_label
            id
            primary {
              text {
                raw
              }
            }
            items {
              text {
                raw
              }
              image {
                alt
                url
                dimensions {
                  width
                  height
                }
                fluid(maxWidth: 500, imgixParams: { maxW: 500 }) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicPageBodyTestimonials {
            slice_label
            id
            primary {
              text {
                raw
              }
            }
            items {
              position
              name
              text {
                raw
              }
              image {
                alt
                url
                dimensions {
                  width
                  height
                }
                fixed(width: 54, height: 54) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
          }
          ... on PrismicPageBodyPricing {
            slice_label
            id
            primary {
              text {
                raw
              }
            }
            items {
              name
              tab
              price {
                raw
              }
              description
              button_label
              button_link {
                url
                target
                uid
                type
              }
            }
          }
          ... on PrismicPageBodyCtaSection {
            slice_label
            items {
              text {
                raw
              }
            }
          }
          ... on PrismicPageBodyAboutUs {
            slice_label
            id
            primary {
              text {
                raw
              }
              flipped
              image {
                alt
                url
                dimensions {
                  width
                  height
                }
                fluid(maxWidth: 500, imgixParams: { maxW: 500 }) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicPageBodyContactForm {
            slice_label
            id
            primary {
              text {
                raw
              }
              form {
                document {
                  ... on PrismicForm {
                    id
                    data {
                      company_label
                      email_label
                      invalid_email_error
                      message_label
                      name_label
                      phone_label
                      required_field_error
                      send_button_label
                      title {
                        text
                      }
                      privacy_policy_error
                      privacy_policy_text {
                        raw
                      }
                      newsletter_text {
                        raw
                      }
                      success_message {
                        raw
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyTeam {
            slice_label
            id
            primary {
              text {
                raw
              }
            }
            items {
              name
              title1
              image {
                alt
                url
                dimensions {
                  width
                  height
                }
                fluid(maxWidth: 200, imgixParams: { maxW: 200 }) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicPageBodyJobs {
            slice_label
            id
            primary {
              text {
                raw
              }
              script_src
            }
          }
          ... on PrismicPageBodyJobsFreelance {
            slice_label
            id
            primary {
              text {
                raw
              }
            }
          }
          ... on PrismicPageBodyClients {
            slice_label
            id
            primary {
              text {
                raw
              }
            }
            items {
              logo {
                dimensions {
                  width
                  height
                }
                url
                fluid(maxWidth: 200, imgixParams: { sat: -100 }) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                alt
              }
            }
          }
          ... on PrismicPageBodyCaseStudies {
            slice_label
            id
            primary {
              text {
                raw
              }
            }
            items {
              case_study {
                document {
                  ... on PrismicPage {
                    id
                    uid
                    data {
                      title {
                        text
                      }
                      body {
                        ... on PrismicPageBodyHeader {
                          id
                          primary {
                            image {
                              alt
                              url
                              fluid(maxWidth: 500, imgixParams: { maxW: 500 }) {
                                ...GatsbyPrismicImageFluid_noBase64
                              }
                            }
                          }
                        }
                        ... on PrismicPageBodyCaseStudyDetails {
                          id
                          primary {
                            client_name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyCaseStudyDetails {
            slice_label
            id
            primary {
              solution {
                raw
              }
              chalange {
                raw
              }
              about {
                raw
              }
              outcome {
                raw
              }
              testimonial_image {
                alt
                url
                dimensions {
                  width
                  height
                }
                fixed(width: 60, height: 60) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
              outcome_image {
                dimensions {
                  width
                  height
                }
                url
                fluid(maxWidth: 1200, imgixParams: { maxW: 1200 }) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              testimonial {
                raw
              }
            }
          }

          ... on PrismicPageBodyText {
            slice_label
            id
            primary {
              variation
              background_highlight
              text {
                raw
              }
            }
          }
          ... on PrismicPageBodyAccordion {
            slice_label
            id
            primary {
              title1 {
                text
              }
            }
            items {
              title1
              text {
                raw
              }
            }
          }
          ... on PrismicPageBodyClusters {
            slice_label
            id
            items {
              label
              text {
                raw
              }
            }
          }
          ... on PrismicPageBodyForkpage {
            id
            primary {
              cta_section {
                document {
                  ... on PrismicCallToAction {
                    id
                    data {
                      options {
                        image {
                          fluid(maxWidth: 500, imgixParams: { maxW: 500 }) {
                            ...GatsbyPrismicImageFluid_noBase64
                          }
                          alt
                          url
                          dimensions {
                            height
                            width
                          }
                        }
                        content {
                          raw
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyRegistrationCtaSection {
            slice_label
            id
            items {
              logos {
                dimensions {
                  width
                  height
                }
                url
                fluid(maxWidth: 140, imgixParams: { maxW: 140 }) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            primary {
              text {
                raw
              }
              logos_text {
                raw
              }
              variation
              cta {
                document {
                  ... on PrismicCallToAction {
                    id
                    data {
                      options {
                        sidebar_link {
                          link_type
                          document {
                            ... on PrismicPage {
                              id
                              uid
                              type
                              lang
                              url
                            }
                          }
                          target
                          url
                        }
                        image {
                          url
                          fluid(maxWidth: 500, imgixParams: { maxW: 500 }) {
                            ...GatsbyPrismicImageFluid_noBase64
                          }
                          alt
                          dimensions {
                            height
                            width
                          }
                        }
                        content {
                          raw
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyRegistrationForm {
            slice_label
            id
            primary {
              cta_component {
                document {
                  ... on PrismicCallToAction {
                    id
                    data {
                      sidebar_title {
                        text
                      }
                      options {
                        sidebar_link {
                          link_type
                          document {
                            ... on PrismicPage {
                              id
                              uid
                              type
                              lang
                              url
                            }
                          }
                          target
                          url
                        }

                        image {
                          url
                          fluid(imgixParams: { maxW: 1200 }) {
                            ...GatsbyPrismicImageFluid_noBase64
                          }
                          alt
                          dimensions {
                            height
                            width
                          }
                        }
                        content {
                          raw
                        }
                      }
                    }
                  }
                }
              }
              form {
                document {
                  ... on PrismicRegistrationForm {
                    id
                    data {
                      ...RegistrationFormData
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyTrust {
            slice_label
            items {
              icon {
                url
                fluid {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                alt
                dimensions {
                  height
                  width
                }
              }
              text {
                html
              }
            }
          }
          ... on PrismicPageBodyHeaderV2 {
            slice_label
            id
            primary {
              inverse
              collapse_on_mobile
              cta {
                document {
                  ... on PrismicCallToAction {
                    id
                    data {
                      options {
                        sidebar_link {
                          link_type
                          document {
                            ... on PrismicPage {
                              id
                              uid
                              type
                              lang
                              url
                            }
                          }
                          target
                          url
                        }
                        image {
                          url
                          fluid(maxWidth: 500, imgixParams: { maxW: 500 }) {
                            ...GatsbyPrismicImageFluid_noBase64
                          }
                          alt
                          dimensions {
                            height
                            width
                          }
                        }
                        content {
                          raw
                        }
                      }
                    }
                  }
                }
              }

              text {
                raw
              }
              logos {
                raw
              }
            }
            items {
              logo {
                dimensions {
                  width
                }
                url
                alt
                fluid(imgixParams: { q: 100, w: 1 }) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicPageBodyHeader32 {
            id
            primary {
              text {
                raw
              }
              logos {
                raw
              }
              featured_image {
                fluid {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                dimensions {
                  height
                  width
                }
                alt
              }
            }
            slice_type
            slice_label
            items {
              logo {
                fluid(imgixParams: { q: 100, w: 1 }) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                dimensions {
                  height
                  width
                }
                alt
              }
            }
          }
          ... on PrismicPageBodyCtaText {
            primary {
              text {
                raw
              }
            }
          }
          ... on PrismicPageBodySplitContent {
            primary {
              background_highlight
              text {
                raw
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                dimensions {
                  width
                  height
                }
                url
                alt
              }
            }
          }
          ... on PrismicPageBodyDownload {
            primary {
              text {
                raw
              }
              form {
                document {
                  ... on PrismicRegistrationForm {
                    id
                    data {
                      ...RegistrationFormData
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyDiveIntro {
            id
            slice_label
            primary {
              intro {
                raw
              }
            }
            items {
              deep_dives {
                document {
                  ... on PrismicPost {
                    id
                    uid
                    type
                    lang
                    data {
                      title {
                        text
                      }
                      featured_image {
                        alt
                        fluid(maxWidth: 100) {
                          ...GatsbyPrismicImageFluid_noBase64
                        }
                        dimensions {
                          width
                          height
                        }
                        url
                      }
                      text {
                        excerpt
                      }
                    }
                  }
                  ... on PrismicDeepDive {
                    id
                    uid
                    type
                    lang
                    data {
                      title {
                        text
                      }
                      featured_image {
                        alt
                        fluid(maxWidth: 100) {
                          ...GatsbyPrismicImageFluid_noBase64
                        }
                        dimensions {
                          width
                          height
                        }
                        url
                      }
                      text: intro {
                        excerpt
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodySuccessPageMessage {
            ...PageSuccessMessage
          }
          ... on PrismicPageBodyRelated {
            id
            primary {
              as_cards
              title: title1
              explore_more {
                raw
              }
            }
            slice_label
            items {
              dives {
                document {
                  ... on PrismicPost {
                    id
                    uid
                    type
                    lang
                    data {
                      title {
                        text
                      }
                      featured_image {
                        alt
                        url
                        dimensions {
                          width
                          height
                        }
                        fluid(maxWidth: 100) {
                          ...GatsbyPrismicImageFluid_noBase64
                        }
                      }
                      text {
                        excerpt
                      }
                    }
                  }
                  ... on PrismicDeepDive {
                    id
                    uid
                    type
                    lang
                    data {
                      title {
                        text
                      }
                      featured_image {
                        alt
                        url
                        dimensions {
                          width
                          height
                        }
                        fluid(maxWidth: 100) {
                          ...GatsbyPrismicImageFluid_noBase64
                        }
                      }
                      text: intro {
                        excerpt
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment RegistrationFormData on PrismicRegistrationFormDataType {
    type
    terms_of_service_text {
      raw
    }
    netlify_form_name
    title {
      raw
    }
    subtitle {
      raw
    }
    additional_text {
      raw
    }
    file {
      url
    }
    success_redirect {
      type
      uid
      lang
    }
    client_registration_redirect {
      type
      uid
      lang
    }
    linguist_registration_redirect {
      type
      uid
      lang
    }
    password_too_short_error
    success: success_message
    error_message
    email_exists_error
    empty_select_placeholder
    send_button_label
    required_field_error_message
    password_mismatch_error
    company_information_label
    languages_and_topics_label
    project_descriptions_label
    newsletter_text {
      raw
    }
    incorrect_email_error_message
    data_privacy_text {
      raw
    }
    fields {
      required
      label
      field_type
      is_multiselect
    }
  }
`
