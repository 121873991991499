import React from "react"
import TextRender from "../TextRender"
import get from "lodash/get"

export default function JobsFreelance({ data }) {
  return (
    <div
      className=" py-16 label-id"
      id="jobs-freelance"
      data-label={data.slice_label}
    >
      <div className="container">
        <div className="prose text-center mx-auto">
          <TextRender text={get(data, "primary.text.raw")} />
        </div>
      </div>
    </div>
  )
}
