import { graphql } from "gatsby"
import React from "react"
import Logo from "../../icons/logo"
import TextRender from "../TextRender"
import tw from "twin.macro"
import { css } from "@emotion/react"
import Icon from "../../images/008-tick.svg"
export default function SuccessPageMessage({ data }) {
  return (
    <div className=" abel-id" id="contact" data-label={data.slice_label}>
      <div className="container py-20 lg:py-40">
        <div className="max-w-sm p-4 mx-auto text-center bg-white shadow-card">
          <div className="flex justify-center py-4">
            <Logo className="h-auto w-28"></Logo>
          </div>
          <div className="grid items-center gap-4 px-4 py-3 my-10 font-semibold text-white rounded-md filter-shadow bg-primary">
            {data?.primary?.green_box}
          </div>
          <div className="flex justify-center ">
            <img src={Icon} />
          </div>

          <div
            className="my-6 "
            css={css`
              a {
                ${tw`font-semibold text-primary`}
              }
            `}
          >
            <TextRender text={data?.primary?.action?.raw} />
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageSuccessMessage on PrismicPageBodySuccessPageMessage {
    id
    slice_label
    primary {
      action {
        raw
      }
      green_box
    }
  }
`
