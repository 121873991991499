import React from "react"
import TextRender from "../TextRender"
import { css } from "@emotion/core"
import tw from "twin.macro"
import get from "lodash/get"

export default function CTASection({ data }) {
  const items = data.items

  return (
    <>
      <div className="py-16 label-id" data-label={data.slice_label}>
        <div className="container">
          <div
            className="grid gap-6   items-stretch justify-center  "
            css={css`
              @media (min-width: 768px) {
                grid-template-columns: repeat(2, 352px);
              }
              > div {
                max-width: 352px;
              }
            `}
          >
            {" "}
            {items &&
              items.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="bg-light flex flex-col items-stretch   w-full   hover:bg-white group filter-shadow rounded p-8 text-center prose max-w-none"
                    css={css`
                      .label {
                        ${tw`font-semibold text-sm text-black group-hover:text-primary!`}
                      }
                      &.group:hover .label {
                        ${tw`text-primary`}
                      }
                      p:last-of-type {
                        ${tw`mt-auto pt-3  `}
                      }
                      .accented {
                        ${tw`font-semibold text-2xl text-black`}
                      }
                    `}
                  >
                    <TextRender text={get(item, "text.raw", [])} />
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}
