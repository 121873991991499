import React from "react"
import TextRender from "../TextRender"
import get from "lodash/get"
import ImageResolver from "../ImageResolver"

export default function Clients({ data }) {
  return (
    <div className="my-20 label-id" data-label={data.slice_label}>
      <div className="container">
        <div className="mx-auto prose text-center">
          <TextRender text={get(data, "primary.text.raw")} />
        </div>
        {data.items && (
          <div className="grid grid-cols-2 gap-16 my-20  md:grid-cols-3 lg:grid-cols-4">
            {data.items.map((item, index) => {
              const logo = get(item, "logo.fluid")
              return (
                <div key={index}>
                  <div className="w-full mx-auto" style={{ maxWidth: 150 }}>
                    {logo && <ImageResolver image={item.logo} />}
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
